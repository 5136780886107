<template>
  <div class="view pa24">
    <el-button type="primary" @click="dialogClassificationVisible = true;companyId = ''"
      >新建分类</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          type="index"
          align="center"
          label="编号"
          show-overflow-tooltip
        />
        <el-table-column prop="name" align="center" label="分类名称" />
        <el-table-column prop="sortNum" align="center" label="排序" />
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.status == true ? false : true"
              active-text="开启"
              @change="(e) => statusChange(e, scope.row)"
              inactive-text="关闭"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="170">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="editClassification(scope.row)"
              size="small"
            >
              编辑
            </el-button>
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="deleteClassification(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              title="确认删除吗？">
              <el-button
                class="ml10"
                style="color: #fd634e"
                type="text"
                slot="reference"
                size="small"
              >
                删除
              </el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      :modal-append-to-body="false"
      destroy-on-close
      @close="close"
      :visible.sync="dialogClassificationVisible"
      width="300px"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="分类名称" prop="name">
          <el-input
            type="text"
            placeholder="请输入名称"
            v-model="ruleForm.name"
            style="width: 200px"
            maxlength="10"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogClassificationVisible = false"
          >取 消</el-button
        >
        <el-button class="btncss" @click="addClassification()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPageClassification,
  updateClassificationStatus,
  deleteClassificationStatus,
  addClassification,
  updateClassification,
} from "@/api/addedServices";
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      dialogClassificationVisible: false,
      ruleForm: {
        name: "",
        dataType: 1,
        status: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      companyId:''
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  created() {
    this.queryPageClassification();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPageClassification()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPageClassification()
    },
    /**@method 模态框关闭的回调 */
    close() {
      this.ruleForm = {
        name: "",
        dataType: 1,
        status: 1,
      }
    },
    async queryPageClassification() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        dataType: 1,
      };
      try {
        this.loading = true;
        const result = await queryPageClassification(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async statusChange(e, row) {
      let data = JSON.parse(JSON.stringify(row));
      data.status = !e;
      await updateClassificationStatus(data);
      this.queryPageClassification();
    },
    async deleteClassification(row) {
      await deleteClassificationStatus(row);
      this.$message({
        message:'删除成功',
        type:'success'
      })
      this.queryPageClassification();
    },
    addClassification() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.companyId !== '') {
            await updateClassification({...this.ruleForm,companyId:this.companyId});
            this.$message({
              message:'保存成功',
              type:'success'
            })
          } else {
            await addClassification(this.ruleForm);
            this.$message({
              message:'保存成功',
              type:'success'
            })
          }
          this.dialogClassificationVisible = false;
          this.$refs.ruleForm.resetFields();
          this.queryPageClassification();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editClassification(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogClassificationVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>